(function($){

  // place your awesome code here

})(window.jQuery);
 


$( document ).ready(function() {  

	setsizes(); 

	smoothScroll();

	stickyScroll();

	$(function() {  
		$('.blog-link').each(function(){
						
			$(this).click(function() {
					window.open($(this).attr('data-href'), $(this).attr('data-target'));
				return false;
			});
		})  
	}(jQuery));

});

/* CHECK FOR CHANGES TO HASHTAG */
$(window).on('hashchange', function(event){
    event.preventDefault(); 
	console.log('hash');
    var hashFound = determineContent();
    if(hashFound) {
    	scrollToHash();
    	return false;
    }
});

$( window ).resize(function() { 
	setsizes();
});

function stickyScroll(){ 

	var stickyScrollBox = $('.sticky-scroll-box');
	var anchor = $('#questions');

	if (stickyScrollBox.length) {
		var topOffset = $('.sticky-scroll-box').offset().top;
		var anchorPoint = $('#questions').offset().top;
	}

	$(window).scroll(function (event) {

	  var y = $(this).scrollTop();

	  if (y >= (topOffset + 20) && y <= (anchorPoint - 400)) {
		$('.sticky-scroll-box').addClass('fixed');
	  } else {
		$('.sticky-scroll-box').removeClass('fixed');
	  }
	  $('.sticky-scroll-box').width($('.sticky-scroll-box').parent().width());
	});
}



function determineContent(){ 
    var hash = window.location.hash; 
 	if($(hash).length){
 		return true;
 	}else{
    	return false; 
 	}
}


function scrollToHash(){ 
	var ele = window.location.hash;
    var calcOffset = $(ele).offset().top; 
    $('html, body').animate({
        scrollTop: calcOffset
    }, 1000);
}

 

function setsizes(){
	var wHeight = $(window).height();

	if(wHeight > 640){
		$('.fullheight').css('min-height', '0px');  
		var minHeight =  wHeight.toString() + 'px';
		$('.fullheight').css('min-height', minHeight);
	}
}

function smoothScroll(){

	if (window.location.hash) {
        var hash = window.location.hash;
        $('html, body').animate({
            scrollTop :  $(hash).offset().top
        }, 1000);
    };

	// Select all links with hashes
	$('a[href*="#"]')

	// Remove links that don't actually link to anything
	.not('[href="#"]')
	.not('[href="#0"]')

	.click(function(event) {

	// On-page links
	if (
		location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
		&& 
		location.hostname == this.hostname
	) {
		// Figure out element to scroll to
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

		// Does a scroll target exist?
		if (target.length) {

		// Only prevent default if animation is actually gonna happen
		event.preventDefault();

		$('html, body').animate({
			scrollTop: target.offset().top
		}, 1000, function() {

			// Callback after animation
			// Must change focus!
			var $target = $(target);
			$target.focus();
			if ($target.is(":focus")) { // Checking if the target was focused
				return false;
			} else {
				$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
				$target.focus(); // Set focus again
			};
		});
		}
	}
	
	});
}